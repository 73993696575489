<template>
  <div>
    <div class="top-bar d-flex align-center mb-3">
      <button
        class="btn btn-primary"
        @click="add = true"
      >
        Create New
      </button>
    </div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>SBC Name</th>
            <th>VIA</th>
            <th>ANY type</th>
            <!--            <th>Any Valid US ANI</th>-->
            <th>Attestation</th>
            <th>Include LRN</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="add || errorMessage">
            <td>
              <!-- <a-input v-model:value="newItem.sbc_id" />-->
              <a-select
                v-model:value="newSbc"
                type="text"
                class="w-100"
              >
                <a-select-option
                  v-for="item in fiteredItems"
                  :key="item.id"
                  :value="item.name"
                >
                  {{
                    item.name
                  }}
                </a-select-option>
              </a-select>
            </td>
            <td>
              <small
                v-if="errors.length"
                class="d-block text-red pb-2"
              >{{ errors[0] }}</small>
              <vue-ip-input
                :ip="newItem.via"
                :on-change="onIpChange"
              />
            </td>
            <td>
              <small
                v-if="errors.length"
                class="d-block text-red pb-2"
              >{{ errors[1] }}</small>
              <a-select
                v-model:value="newItem.ani_type"
                type="text"
                class="w-100"
              >
                <a-select-option
                  v-for="item in types"
                  :key="item.id"
                  :value="item.value"
                >
                  {{
                    item.name
                  }}
                </a-select-option>
              </a-select>
            </td>
            <td>
              <small
                v-if="errors.length"
                class="d-block text-red pb-2"
              >{{ errors[2] }}</small>
              <a-select
                v-model:value="newItem.attestation"
                type="text"
                class="w-100"
              >
                <a-select-option
                  v-for="item in attestations"
                  :key="item.id"
                  :value="item.value"
                >
                  {{
                    item.name
                  }}
                </a-select-option>
              </a-select>
            </td>
            <td class="policies_lrn">
              <a-checkbox
                v-model:checked="newItem.include_lrn"
              />
            </td>
            <td class="d-flex">
              <a
                class="a_action_icon mb-2 mr-2"
                @click="addNewItem"
              >
                <i
                  class="fa fa-floppy-o"
                  aria-hidden="true"
                />
              </a>
              <a
                class="a_action_icon mb-2 mr-2"
                @click="removeCreate"
              >
                <i
                  class="	fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </td>
          </tr>
          <template v-if="items">
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.sbc_name }}</td>
              <td>{{ item.via }}</td>
              <!--            <td>item</td>-->
              <td>{{ item.ani_type }}</td>
              <td>{{ item.attestation }}</td>
              <td
                style="max-width: 120px"
              >
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Active
                  </template>
                  <a
                    v-if="item.include_lrn"
                    class="a_action_icon bg-success d-flex mb-2 mr-2"
                    @click="updateInclude(item)"
                  >
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Inactive
                  </template>
                  <a
                    v-if="!item.include_lrn"
                    class="a_action_icon bg-danger d-flex mb-2 mr-2"
                    @click="updateInclude(item)"
                  >
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
              </td>
              <td class="d-flex">
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Delete
                  </template>
                  <a
                    class="a_action_icon bg-secondary mb-2 mr-2"
                    @click="deleteItem(item)"
                  >
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="right-pagination"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
import VueIpInput from 'vue-ip-input'
import axios from '@/axios'
import _ from 'lodash'
import {Modal, notification} from 'ant-design-vue'

export default {
  components: {
    'vue-ip-input': VueIpInput
  },
  data() {
    return {
      add: false,
      step: 10,
      skip: 0,
      total: null,
      items: null,
      errorMessage: '',
      errors: [],
      types: [
        {value: 'ALL_NUMBERS', name: 'all number'},
        {value: 'ANY_VALID_US_NUMBERS', name: 'any valid US numbers'},
        {value: 'SP_OWNS_NUMBER', name: 'your owns number'}
      ],
      attestations: [
        {value: 'A', name: 'A'},
        {value: 'B', name: 'B'},
        {value: 'C', name: 'C'},
      ],
      sbc: [{id: '', name: 'all'}],
      newSbc: "all",
      newItem: {
        via: '',
        ani_type: '',
        attestation:'',
        include_lrn: false,
        sbc_id: 'all'
      },
      allNames: []
    }
  },
  computed: {
    fiteredItems() {
      return  _.uniqBy(this.allNames, 'name');
    }
  },
  mounted() {
    this.loadItems()
    this.getSbc()
  },
  created() {
    this.fetchAllNames()
  },

  methods: {
    removeCreate(){
      this.add = false;
      this.newItem = ""
    },
    checkForm() {
      if(this.newItem.via && this.newItem.ani_type && this.newItem.attestation && this.newItem.include_lrn && this.newSbc) return true;
      setTimeout(() => {
        this.errors = [];
      },5000)
      if(this.newItem.via === '') this.errors.push("via should not be empty");
      if(!this.newItem.ani_type ) this.errors.push("ani_type must be a valid enum value.");
      if(!this.newItem.attestation ) this.errors.push("attestation must be a valid enum value");
    },
    onIpChange(ip) {
      this.newItem.via = ip
      console.log(this.newItem.via)
    },
    addNewItem() {
      const params = {
        via: this.newItem.via,
        ani_type: this.newItem.ani_type,
        attestation: this.newItem.attestation,
        include_lrn: this.newItem.include_lrn ? this.newItem.include_lrn : false,
        sbc_id: this.newSbc
      };
      this.checkForm()
      axios
          .post('/authentication/policy', params)
          .then((res) => {
            this.$store.dispatch('created', 'item')
            this.errors = []
            console.log('res', res)
          })
          .catch((err) => {
            if(err) {
              this.errorMessage = err.response.data.message
            }
            notification.error({
              message: this.errorMessage,
            })
          }).finally(() => {
        setTimeout(() => {
          this.errors = [];
        },5000)
        this.newItem = {};

        this.loadItems()
      })
    },
    loadItems() {
      axios
          .get(`/authentication/policy?order_dir=asc&order_by=created_on&take=10&skip=${this.skip}`)
          .then((res) => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count : res.data.count
          })
          .catch((err) => {
            console.log(err)
          })
    },
    fetchAllNames() {
      axios.get('/authentication/sbc?order_dir=DESC&order_by=created_on&take=9000&skip=0')
      .then((response) => {
        this.allNames = response.data.data
      })
    },
    getSbc() {
      axios
          .get('/authentication/sbc?order_dir=asc&order_by=created_on&take=10&skip=0')
          .then((res) => {
            this.sbc = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },
    deleteItem(item) {
      const vm = this
      Modal.confirm({
        title: 'Do you Want to delete this item?',
        // content: (h) => <div style="color:red;">Some descriptions</div>,
        onOk() {
          vm.deleteReq(item)
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    deleteReq(item) {
      axios
          .delete(`/authentication/policy/${item.id}`)
          .then((res) => {
            this.$store.dispatch('deleted', 'item')
            this.loadItems()
          })
          .catch((err) => {
            console.log(err)
          })
    },
    updateInclude(item) {
      item.include_lrn = !item.include_lrn;
      axios.put(`/authentication/policy/${item.id}`, item).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    }
  }
}
</script>
<style scoped>
.ant-checkbox-inner {
  padding: 11px 11px 8px 8px;
}
</style>
<style>
.a_action_icon {
  width: 28px;
  height: 28px;
  background-color: #9e9e9e;
  border-radius: 50%;
}

.a_action_icon > i {
  font-size: 20px;
  color: white;
  margin: 4px 6px;
}

.policies_lrn > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
  padding: 11px 11px 8px 8px;
}

.right-pagination {
  text-align: right !important;
  margin-right: 86px !important;
}
 .ip-input-container {
   display: inline-block;
   height: 32px!important;
   width: 100%!important;
   font-variant: tabular-nums!important;
   list-style: none!important;
   font-feature-settings: 'tnum' !important;
   position: relative!important;
   padding: 4px 11px!important;
   color: #595c97!important;
   font-size: 14px!important;
   line-height: 1.5715!important;
   background-color: #ffffff!important;
   background-image: none!important;
   border: 1px solid #e4e9f0!important;
   border-radius: 8px!important;
   transition: all 0.3s!important;
 }
.ip-input-container:hover {
  border-color: #78a3ff!important;
  border-right-width: 1px !important;
}

</style>